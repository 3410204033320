export interface IState {
	menuItems: IMenuItem[],
	rootPage: IRootPage,
	endpoints: {
		getChildren: string
	}
}
export interface IMenuItem {
	name: string,
	children: IMenuItem[],
	isExpanded: boolean,
	id: string,
	level: number
}
export interface IRootPage {
	name: string,
	uri: string
}
const deafultState: IState = {
	menuItems: [],
	rootPage: {name: '', uri: ''},
	endpoints: {
		getChildren: ''
	}
}

export default deafultState;
