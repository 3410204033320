













import Vue from 'vue'
import MenuItem from './components/MenuItem.vue';
import { IMenuItem, IRootPage } from './store/state'
// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	menuItems: IMenuItem[]
	rootPage: IRootPage
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
        menuItems() {
            console.log(this.$store.getters.menuItems)
            return this.$store.getters.menuItems;
        },
		rootPage() {
			return this.$store.getters.rootPage;
		}
	},
	components: {
		MenuItem
	}
})
