

















import Vue from 'vue'
import { toggleExpanded } from '../store/actions'
//import IMenuItem
interface MenuItemProps {
	item: {}
}
interface MenuItemMethods {
    toggleClick(): void
}

export default Vue.extend<{}, MenuItemMethods, {}, MenuItemProps>({
	name: 'MenuItem',
	props: {
		item: {}
	},
    methods: {
        toggleClick() {
            this.$store.dispatch(toggleExpanded(this.$props.item))
        }
    },
    computed: {
        isExpandedStyle() {
            return this.$props.item.isExpanded ? 'block' : 'none'
        }
    }
    
})
