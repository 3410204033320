import { MutationTree, Mutation } from 'vuex';
import { IMenuItem, IState } from './state';
import { ActionPayload, IAddMenuItemsPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}

const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
	
}
const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	toggleExpanded: toggleExpandedMutation,
	addMenuItems: addMenuItemsMutation
}

export default mutations
